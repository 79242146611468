<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ConsolidatedChart from "./consolidatedChart";
import {
  layoutMethods,
  layoutComputed,
  consolidatedMethods,
  consolidatedComputed,
} from "@/state/helpers";
/**
 * Consolidated component
 */
export default {
  page: {
    title: "Consolidated",
    meta: [{ name: "Consolidated" }],
  },
  components: {
    Layout,
    PageHeader,
    ConsolidatedChart,
  },
  mounted() {
    this.changeMenuType();
  },
  created() {
    this.retrieveConsolidated();
  },
  data() {
    return {
      title: "titles.consolidated.text",

      loaderData: true,

      cptStates: {
        offline: 0,
        lessZero: 0,
        zero: 0,
        two: 0,
        eight: 0,
        twelve: 0,
        fourteen: 0,
      },

      totalDevices : null,

      consolidatedList: {
        "Tanger-Tetouan-Al Hoceima": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Oriental": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Fès-Meknès": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Rabat-Salé-Kénitra": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Béni Mellal-Khénifra": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Grand Casablanca-Settat": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Marrakech-Safi": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Drâa-Tafilalet": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Souss-Massa": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Guelmim-Oued Noun": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Laayoune-Sakia El Hamra": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
        "Eddakhla-Oued Eddahab": {
          offline: 0,
          lessZero: 0,
          zero: 0,
          two: 0,
          eight: 0,
          twelve: 0,
          fourteen: 0,
        },
      },
      consolidatedData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...consolidatedComputed,
    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.consolidated.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...layoutMethods,
    ...consolidatedMethods,

    retrieveConsolidated() {
      this.getConsolidatedBy().then(()=>{
        this.loaderData = false;
      });
    },
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
  },
  watch: {
    getConsolidated(newVal) {
      let keys = Object.keys(newVal);
      this.totalDevices = keys.length;
      keys.map((obj) => {
        if (newVal[obj].event && newVal[obj].region) {
          let now = new Date();
          let dateEvent = new Date(newVal[obj].event.createdAt);
          var diffMs = now.getTime() - dateEvent.getTime();
          if (diffMs > 1000 * 60 * 15) {
            this.consolidatedList[newVal[obj].region.name].offline += 1;
            this.cptStates.offline += 1;
          } else {
            let temp = newVal[obj].event.temp;
            if (temp < 0){
              this.consolidatedList[newVal[obj].region.name].lessZero += 1;
              this.cptStates.lessZero += 1;
            }
            else if (temp <= 2){
              this.consolidatedList[newVal[obj].region.name].zero += 1;
              this.cptStates.zero += 1;
            }
              
            else if (temp <= 8){
              this.consolidatedList[newVal[obj].region.name].two += 1;
              this.cptStates.two += 1;
            }
              
            else if (temp <= 12){
              this.consolidatedList[newVal[obj].region.name].eight += 1;
              this.cptStates.eight += 1;
            }
              
            else if (temp <= 14){
              this.consolidatedList[newVal[obj].region.name].twelve += 1;
              this.cptStates.twelve += 1;
            }
              
            else if (temp > 14){
              this.consolidatedList[newVal[obj].region.name].fourteen += 1;
              this.cptStates.fourteen += 1;
            }
              
          }
        } else if (newVal[obj].region) {
          this.consolidatedList[newVal[obj].region.name].offline += 1;
          this.cptStates.offline += 1;
        }
      });
      this.consolidatedData = this.consolidatedList;
    },
  },
  beforeDestroy() {
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <ConsolidatedChart v-bind:events="consolidatedData" :states="cptStates" :total="totalDevices" :loaderData="loaderData" />
  </Layout>
</template>